$border-color: #d7d7d7;
$transitionSpeed: 0.2s;
$gutter: 1rem;

:root {
  --gutter: 1rem;
}

$border-radius: var(--gutter);

$info-color: #006bb3;

$primary-color: #2da2d1;

$background-body: #f9f9f9;

$background-success: #36de97;
$background-warning: #f1aa3f;
$background-error: #e95649;

$background-success-light: #b4f3d9;
$background-warning-light: #ffe4bb;
$background-error-light: #ffd0cc;

$breakpoints: (
  "small": 767px,
  "medium": 992px,
  "large": 1200px,
) !default;

/// Mixin to manage responsive breakpoints
/// @author Kitty Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

:root {
  --gutter: 1rem;
  --baseFontSize: 18px;

  @include respond-to("small") {
    --gutter: 0.5rem;
    --baseFontSize: 15px;
  }
}
