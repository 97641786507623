/* LoadingScreen.css */

.loading-screen.hidden {
  opacity: 0;
  transition: opacity 0.7s ease-in-out;
}

.loading-screen.below {
  z-index: -1;
}


.loading-screen {
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Set the background color to white with some transparency */
  background-color: rgba(255, 255, 255, 1);
  /* Set a high z-index to make sure it's on top of everything */
  z-index: 9999;
}

.logo {
  /* width: 600px; */
  width: 30rem;
  /* height: 180px; */
  height: 10rem;
}

.loading-status {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 0.45rem solid rgba(0, 0, 0, 0.1);
  /* border-left: 4px solid #3498db; */
  border-left: 0.45rem solid #2499d5;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

/* Responsive styling for small screens */
@media screen and (max-width: 600px) {
  .logo {
    /* width: 600px; */
    width: 20rem;
    /* height: 180px; */
    height: 5.5rem;
  }

  .spinner {
    border: 0.375rem solid rgba(0, 0, 0, 0.1);
    /* border-left: 4px solid #3498db; */
    border-left: 0.375rem solid #2499d5;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 1.2rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}