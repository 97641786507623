@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --light-blue: #2499d5;
  --grey: #294a5c;
  --light-grey: #D1D5DB;
  --dark-grey: #95999e;
  --blue: #383eff;
  --blue-transparent: #383fff80;
  --green: #00bf4f;
  --red: #ff4e4e;
  --red-transparent: #ff4e4e82;
  --orange: #ff6803;
  --pink: #ff177c;
  --yellow: #e1c700;
}

.red-text {
  color: var(--red);
}

.orange-text {
  color: var(--orange);
}

.green-text {
  color: var(--green);
}

.blue-text {
  color: var(--light-blue);
}

.rv-xy-plot__axis__tick__text {
    font-size: 1rem !important;
}

.fsReport,
.fsReportContent {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.fsReport {
  display: flex;
  position: relative;
}

.fsReportSideBar {
  height: 100vh;
}