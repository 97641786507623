.Page {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000000;

    h1 {
        font-size: 28px;
        margin-bottom: 0;
    }

    &__logo {
        width: 100px;
        margin: 80px 0 0;
    }
}