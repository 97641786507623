@media only screen and (min-width: 750px){

body {
  background-color: #f5f6f8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.css-8er80j-MuiPaper-root-MuiTableContainer-root {
  background-color: #fff !important;
}

.boxHeader {
  margin: 1.25rem;
  margin-left: 3.125rem;
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.boxOverviewDetails {
  margin: 1.25rem;
  margin-left: 3.125rem;
  margin-top: 3.125rem;
  background-color: #2499d5;
  box-shadow: 2;
  width: 150px;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
}

.typoOverviewDetails {
  font-weight: 600;
  font-size: 20px !important;
  color: white;
  display: flex;
}

#boxGlobalSavingsCo2 {
  margin: 1.25rem;
  margin-left: 3.125rem;
  display: flex;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 8.75rem;
  gap: 1.25rem;
  height: 150px;
  width: 80%;
}

.boxSavingsCo2 {
  box-shadow: rgba(0, 0, 0, 0.35) 0rem 0.313rem 0.938rem;
  border-radius: 0.625rem;
  width: 25rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxCo2 {
    box-shadow: rgba(0, 0, 0, 0.35) 0rem 0.313rem 0.938rem;
  border-radius: 0.625rem;
  width: 25rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  }

.iconSavingsCo2 {
  color: green;
  font-size: 2.625rem !important;
  display: flex !important;
}
.iconCo2 {
    color: green;
    font-size: 2.625rem !important;
    display: flex !important;
  }

.boxTableContainer {
  margin: 1.25rem;
  margin-left: 3.125rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0rem 0.313rem 0.938rem;
  max-width: 51.25rem;
  width: 80%;
}

.tableCellTitle {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
  color: white !important;
  text-align: center !important;
}

.tableCellItems {
  color: #3d5170  !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  text-align: center !important;
}
.boxTableContainerMobile {
    display: none;
}
}


@media only screen and (max-width: 750px){

    body {
      background-color: #f5f6f8 !important;
      color: rgba(0, 0, 0, 0.87) !important;
    }
    
    .css-8er80j-MuiPaper-root-MuiTableContainer-root {
      background-color: #fff !important;
    }
    
    .mainBox {
        padding-bottom: 50px; 
    }
    .boxHeader {
      margin: 1.25rem;
      margin-left: 2rem;
      margin-top: 3.5rem;
      display: flex;
      justify-content: space-between;
      align-content: center;
      
    }
    
    .boxOverviewDetails {
      margin: 1.25rem;
      margin-left: 2rem;
      margin-top: 3.125rem;
      background-color: #2499d5;
      box-shadow: 2;
      width: 150px;
      border-radius: 0.25rem;
      display: flex;
      justify-content: center;
    }
    
    .typoOverviewDetails {
      font-weight: 600;
      font-size: 20px !important;
      color: white;
      display: flex;
    }
    
    #boxGlobalSavingsCo2 {
      margin: 1.25rem;
      margin-left: 2rem;
      display: grid;
      gap: 1.25rem;
      height: 250px;
    }
   

    .boxSavingsCo2 {
      box-shadow: rgba(0, 0, 0, 0.35) 0rem 0.313rem 0.938rem;
      border-radius: 0.625rem;
      max-width: 20rem;
      width: 100%;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .boxCo2 {
        box-shadow: rgba(0, 0, 0, 0.35) 0rem 0.313rem 0.938rem;
        border-radius: 0.625rem;
        max-width: 20rem;
        width: 100%;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    .iconSavingsCo2 {
      color: green;
      font-size: 2rem !important;
      display: flex !important;
      margin-left: 10px;
    }
    .iconCo2 {
        color: green;
        font-size: 2.5rem !important;
        display: flex !important;
        margin-left: 65px;
      }
    
    .boxTableContainer {
      display: none;
    }

    .boxTableContainerMobile {
        margin: 1.25rem;
        margin-left: 2rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0rem 0.313rem 0.938rem; 
        
      
      }
      
      .tableCellTitleMobile {
        font-size: 1.125rem !important;
        font-weight: 600 !important;
        color: white !important;
        text-align: left !important;
      }
      
      .tableCellItemsMobile {
        color: #3d5170  !important;
        font-size: 1rem !important;
        font-weight: 400 !important;
        text-align: left !important;
      }

    }

