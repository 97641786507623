@import "variables.scss";


* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
}

body {
  background-color: $background-body;
  font-family: 'Roboto', sans-serif;
  font-size: var(--baseFontSize);
}