.loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader::before,
  .loader::after {
    position: absolute;
    content: "";
    height: 8em;
    width: 8em;
    border: 1em solid #0060fa;
    border-radius: 50%;
    animation: loader_79178 2s linear infinite;
  }
  
  .loader::after {
    opacity: 0;
    animation-delay: 1s;
  }
  
  @keyframes loader_79178 {
    0% {
      border: 1em solid #0060fa;
      transform: scale(0);
      opacity: 1;
    }
  
    100% {
      border: 0 solid #0060fa;
      transform: scale(1);
      opacity: 0;
    }
  }


  @media only screen and (min-width: 750px)
  {

    .boxPieChart {
      margin:40px 0 0 0; 
      height:75vh; 
      width:50vw;
    }
    
    .boxDataGrid {
      margin:40px 0 0 0 !important;
      height:75vh !important;
      width:25vw !important;
      
      
    }

    .MuiDataGrid-root {
      border: none !important;
      background-color: #2499D5 !important;
      font-weight:bold !important;
      
    }
    .MuiDataGrid-columnHeaders {
      background-color: #2499D5 !important;
      color : white !important;

    }
     .MuiDataGrid-virtualScroller {
      background-color:white !important
    }
    .MuiDataGrid-footerContainer {
      background-color: #2499D5 !important;
      color: white !important;

    }
    .MuiDataGrid-toolbarContainer .MuiButton-text {
      color: white !important;
    }
    .MuiCheckbox-root svg {
      color:black !important;
    }
  }

  @media only screen and (max-width: 750px)
  {
    .boxPieChart {
      height: 35vh;
      width:70vw;
      
    }

    .boxDataGrid {
      margin:40px 0 0 0 !important;
      height:60vh !important;
      width:66vw !important;
      margin-top: 230px !important;
      position: absolute;
      margin-left: 6px !important;
      padding-bottom: 50px !important;
      
    }

    .MuiDataGrid-root {
      border: none !important;
      background-color: #2499D5 !important;
      font-weight:bold !important;
      
    }
    .MuiDataGrid-columnHeaders {
      background-color: #2499D5 !important;
      color : white !important;

    }
     .MuiDataGrid-virtualScroller {
      background-color:white !important
    }
    .MuiDataGrid-footerContainer {
      background-color: #2499D5 !important;
      color: white !important;

    }
    .MuiDataGrid-toolbarContainer .MuiButton-text {
      color: white !important;
    }
    .MuiCheckbox-root svg {
      color:black !important;
    }
  }