@import "../../variables.scss";

.Sensors {
  margin: 0 auto;
  padding: 16px;
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-size: 0.875rem;

  p {
    margin: 0;
    font-size: 0.875rem;
  }

  &__componentCard {
    border-radius: 8px !important;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 4px 16px 0px lightgrey !important;
    }
  }

  &__cardHeader {
    padding: 8px;
    background-color: $primary-color;
    color: $background-body;
  }

  &__cardContent {
    padding: 0px 8px;
  }

  &__badge {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 16px;
    border-radius: 8px;
    font-weight: 500;
    min-width: 125px;
    margin: 0 auto 10px;
    text-align: center;
    max-width: 150px;

    svg {
      height: 24px;
    }

    [data-type="success"] & {
      background-color: $background-success-light;
    }

    [data-type="warning"] & {
      background-color: $background-warning-light;
      svg {
        transform: rotate(90deg);
      }
    }

    [data-type="error"] & {
      background-color: $background-error-light;
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
