@import "../../variables.scss";


.AirQuality {
  max-width: 900px;
  margin: var(--gutter) auto;
  padding: var(--gutter) / 2;
  font-family: "Roboto", sans-serif;
  color: #000000;

  &__logo {
    width: 100px;
    margin: var(--gutter);
    float: right;
  }
}

.DataContainer {
  margin: var(--gutter);
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background-color: #ffffff;
  }

  &__content {
    width: 100%;
    padding: var(--gutter);
  }

  &__info {
    width: 90%;
    text-align: center;
    max-height: 0;
    opacity: 0;
    transition: all $transitionSpeed;
    overflow: hidden;

    .DataContainer--open & {
      max-height: 500px;
      opacity: 1;
      overflow: auto;
    }
  }

  &__title {
    width: 100%;
    text-align: center;
    padding: var(--gutter) 0;
    background-color: #ffffff;
    border-bottom: 1px solid $border-color;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    margin: 0 0 0 0;
    color: #131313;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.1rem;
    user-select: none;

    &-icon {
      margin-left: var(--gutter);
      transition: transform $transitionSpeed;
      transform: rotate(180deg);
      position: relative;

      .DataContainer--open & {
        transform: rotate(0);
        top: 3px;
      }
    }
  }
}


.Info {
  &__wrapper {
    display: flex;
    padding: var(--gutter);
    justify-content: space-between;
    flex: 1;

    @include respond-to("small") {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  &__value-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @include respond-to("small") {
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 5px;
    }
  }

  &__value-container {
    margin-bottom: calc(var(--gutter) * 0.7);

    [data-hide="true"] & {
      display: none;
    }

    @include respond-to("small") {
      width: 70px;
      text-align: center;
      margin-left: 20px;
      margin-bottom: 0;
    }
  }

  &__value {
    padding: 2px 10px;
    border-radius: 8px;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;

    @include respond-to("small") {
      margin-bottom: 0;
      margin-right: var(--gutter);
    }

    [data-active="true"][data-type="success"] & {
      background-color: #b4f3d9;
    }

    [data-active="true"][data-type="warning"] & {
      background-color: #ffe4bb;
    }

    [data-active="true"][data-type="error"] & {
      background-color: #ffd0cc;
    }
  }

  &__description {
    padding: 2px 10px;
    border-radius: 8px;
    text-align: center;
    background: none;
    font-size: 1.1rem;

    [data-hide="true"] & {
      display: none;
    }

    [data-active="true"][data-type="success"] & {
      background-color: $background-success-light;
    }

    [data-active="true"][data-type="warning"] & {
      background-color: $background-warning-light;
    }

    [data-active="true"][data-type="error"] & {
      background-color: $background-error-light;
    }
  }
}
